import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import jingxue from '../../assets/images/team/jingxue.jpg'
import jane from '../../assets/images/team/jane.jpg'
import team1 from '../../assets/images/scientist/scientist1.png'
import team2 from '../../assets/images/scientist/scientist2.png'
import team3 from '../../assets/images/scientist/scientist3.png'
import commingsoon from '../../assets/images/team/Coming-Soon-Light.png'

const TeamMembers = () => {
    return (
        <section className="scientist-area pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="banner" /> 
                        Team Members
                    </span>
                    <h2>Our Awesome Team</h2>
                    <p>Creative innovative interior design solutions for your home.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={team3} alt="banner" />
                            </div>
                            <div className="content">
                                <h3>Jane</h3>
                                <span>Founder</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={team1} alt="banner" />
                            </div>
                            <div className="content">
                                <h3>Mia</h3>
                                <span>Photographer</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={team2} alt="banner" />
                            </div>
                            <div className="content">
                                <h3>Eric Cheung</h3>
                                <span>Designer</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TeamMembers