import React from 'react'
import Layout from "../components/App/Layout"
import NavbarStaging from "../components/App/NavbarStaging"
import Footer from "../components/App/Footer"
import ContactInfo from '../components/Contact/ContactInfo'
import ContactForm from '../components/Contact/ContactForm'
import TeamMembers from "../components/DigitalMarketingAgency/TeamMembers"

const Contact = () => {
    return (
        <Layout>
            <NavbarStaging />
            <TeamMembers />
            <ContactForm />
            <ContactInfo />
            <Footer />
        </Layout>
    );
}

export default Contact